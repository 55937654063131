import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';


export async function confirm(body) {
  try {
    const result = await RestClient.post('auth', 'confirm', body)
    console.log("TCL: confirmation -> result", result)
  } catch (error) {
    return errorMessage(error)
  }
}