import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import history from '../../lib/history';
import { getProfile, uploadAvatar } from './actions';
import config from '../../config';
import ResetModal from './components/ResetModal';


class Profile extends Component {
  componentDidMount(){
    this.props.getProfile()
  }

  onChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.props.uploadAvatar({avatar: reader.result});
    };
  } 


  render() {
    const {profile} = this.props;
    console.log("TCL: Profile -> render -> profile", profile)
    let img = '/images/noavatar.png';

    if(profile.avatar != 'images/avatar/profile.png'){
      img = config.apiUrl + '/' +  profile.avatar;
    }

    return (
      <div className="Profile row">

        <div className="profile-avatar col-md-2">
          <div className="avatar" style={{backgroundImage: `url(${img})`}}>

          </div>
            <label className="btn btn-warning">
              {I18n.t('btnChangeAvatar')}
              <input type="file" style={{display: 'none'}} accept="image/*"  onChange={this.onChange} />
            </label>
        </div>
        <div className="profile-info col-md-10">
          <p>{I18n.t('titleProfileFirstname')}: {profile.firstname}</p>
          <p>{I18n.t('titleProfileLastname')}: {profile.lastname}</p>
          <p>{I18n.t('titleProfileEmail')}: {profile.email}</p>
          <ResetModal />
        </div>
        <div className="col-md-12 actions" >
          <button onClick={() => {history.push('/')}} className="btn btn-primary">{I18n.t('navStDashboard')}</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.auth.profile,
  
})

const mapDispatchToProps = {
  getProfile,
  uploadAvatar,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
