import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import getTime from '../../../../lib/getTimeHHMMSS';

class StaticItem extends Component {
  render() {
    const { index, item, questions_length } = this.props;
    const procent = 100 / questions_length * item.true_count_answer;

    const seconds = item.date_end - item.date_start;

    return (
    <div className="StaticItem"> {I18n.t('txtStAttem')} {index+1} ({procent.toFixed(2)}%, {item.true_count_answer} {I18n.t('txtCorrectAnswer')}, {item.count_answer - item.true_count_answer} {I18n.t('txtIncorrectAnswer')}, {questions_length - item.count_answer} {I18n.t('txtInQuestion')}) {moment(item.date_end*1000).format('DD/MM/YYYY HH:mm')} ({getTime(seconds)})</div>
    );
  }
}

export default StaticItem;
