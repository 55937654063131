const defaultState = {
  is_loaded: false,
  list: [],
  active_item: null,
  quiz: {},
  student_answer: null,
}

const questions = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_QUESTIONS':
      return {
        ...state,
        list: action.data,
        is_loaded: true,
        active_item: state.active_item == null ? (action.data[0] || {}) : state.active_item
      };
    case 'SET_QUESTION_STUDENT_ANSWER':
      return {
        ...state,
        student_answer: action.data,
        quiz: action.quiz ? action.quiz : state.quiz,
      }
    case 'SET_QUESTION_ITEM':
      return {
        ...state,
        active_item: action.data
      };
    case 'SET_QUESTIONS_QUIZ':
      return {
        ...state,
        quiz: action.data
      };
    case 'SET_QUESTION_ASWER':
      return {
        ...state,
        list: state.list.map(item => {
          if(item.id == action.data.question_id) {
            return {...item, is_answer: true, student_answer: action.data}
          }
          return {...item};
        }),
        active_item: {...state.active_item, student_answer: action.data, is_answer: true}
      };
    case 'SET_QUESTION_CLEAR':
      return defaultState;
    default:
      return state
  }
}

export default questions;
