const defaultState = {
  is_loaded: false,
  list: []
}

const quizs = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_QUIZS':
      return {
        ...state,
        list: action.data,
        is_loaded: true,
      };
    default:
      return state
  }
}

export default quizs;
