import RestClient from 'flex-rest-client';
import config from './index';



const rs = new RestClient();


function checkHttpStatus(response) {
  if (response.status == 200) {
      return response;
  } else {
    return Promise.resolve().then(() => {
      return response.json();
    }).then(res => {
        res.status = response.status;
        return Promise.reject(res)
    });
  }
}

function makeRequest({url, method, headers, bodyJSObject}) {
  const body = bodyJSObject ? JSON.stringify(bodyJSObject) : undefined;
  return fetch(url,
      {
          method: method || 'GET',
          headers,
          body,
      }).then(checkHttpStatus).then((response) => {
      return response.json();
      }).then(res => {
        if(res.errcode == 200){
          return res;
        } else {
          return Promise.reject(res)
        }
      });
}

const options = {
  baseUrl: config.apiUrl + '/api/student',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With' : 'XMLHttpRequest',
  },
  credentials: 'same-origin',
  requestMethod: makeRequest,
};

if (localStorage.getItem('token')) {
  options.headers['Token'] = 'Bearer '+localStorage.getItem('token');
}

rs.config(options);

export default rs;