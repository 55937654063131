import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getRooms, setRoomInvite } from './actions';
import './style.scss';
import Room from './components/Room';
import Spiner from '../../components/Spiner';
import { I18n } from 'react-redux-i18n';

class Rooms extends Component {
  state = {loaded: false};
  async componentDidMount() {
    await this.props.getRooms();
    this.setState({loaded: true})
    
    try {
      let room_inivite = localStorage.getItem('room_inivite');
      const url = new URL( window.location.href);
      if(url){
       const params = url.searchParams;
        if(params.get('room_inivite')){
          room_inivite = params.get('room_inivite')
        }
      }
      if(room_inivite){
        setRoomInvite(room_inivite);
        localStorage.removeItem('room_inivite');
      }
    } catch (error) {
      
    }

  }

  render() {
    const {rooms} = this.props;
    const { loaded } = this.state;
    if(!loaded){
      return <Spiner />
    }
    return (
      <div className="Rooms row">
        {!rooms.length && <div className="msg-info">{I18n.t('msgNotFoundRooms')}</div> }
        {rooms.map((room) => <Room key={'room-' + room.id} room={room}/>)}
      </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => ({
  rooms: state.rooms.list
})

const mapDispatchToProps = {
  getRooms
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)
