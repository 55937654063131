import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';
import history from '../../lib/history';

export function login(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('auth', 'login', body)
      console.log("TCL: confirmation -> result", result)
      RestClient.config({
        headers: {
          'Token' : 'Bearer ' + result.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With' : 'XMLHttpRequest',
        }
      })
      localStorage.setItem('token', result.token);
      dispatch({type: 'SET_PROFILE', data: result.user});
      history.push('/')
    } catch (error) {
      if(error.message == 'Hash is invalid.'){
        error.message = 'errorUserNotActivate'
      }
      errorMessage(error)
    }
  }
}