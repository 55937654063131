import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';
import history from '../../lib/history';
import {orderBy} from 'lodash';

export function getQuizs(room_id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('room', 'quizs', {room_id});

      let quizs = result.payload.map(i => {
				if(i.x_order){
					i.x_order = parseInt(i.x_order);
				}
				i.id = parseInt(i.id);
				return i;
			})

		

      dispatch({type: 'GET_QUIZS', data: orderBy(quizs, ['x_order', 'id'], ['asc', 'asc'])});
    } catch (error) {
      errorMessage(error)
    }
  }
}


export function quizStart(quiz_id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'start', {quiz_id})
      dispatch({type: 'SET_QUESTION_STUDENT_ANSWER', data: result.payload, quiz: result.quiz});
      history.push('/quiz/' + quiz_id)
      return result.payload;
    } catch (error) {
      errorMessage(error)
    }
  }
}