import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators'
import './style.scss';
import FieldInput from '../../components/Fields/FieldInput';
import { login } from './actions';
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import history from '../../lib/history';


class Login extends Component {

  componentDidMount(){
    console.log('w', window.location)

    try {
      const url = new URL( window.location.href);
      if(url){
       const params = url.searchParams;
        if(params.get('room_inivite')){
          localStorage.setItem('room_inivite', params.get('room_inivite'))
        }
      }
    } catch (error) {
      
    }
    
  }

  submit = async (data) =>{
    try {
      await this.props.login(data);
    } catch (error) {

    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="Login">
        <form className="form-login" onSubmit={handleSubmit(this.submit)}>
          <h1>{I18n.t('stPageLoginTitle')}</h1>
          <p>{I18n.t('stPageLoginDescription')}:</p>
          <Field 
            name='username'
            label={I18n.t('stPageLoginTitleFieldEmail')}
            component={FieldInput}
            validate={[required()]}
          />
          <Field 
            name='password'
            label={I18n.t('stPageLoginTitleFieldPassword')}
            component={FieldInput}
            validate={[required()]}
            type="password"
          />
          <div className="block-forgot">
            <div className="lnk" onClick={() => {
              history.push('/forgot');
            }}>{I18n.t('codeForgot')}</div>
          </div>
          <button type="submit" className="btn btn-primary">{I18n.t('stPageLoginTitleBtn')}</button>
        </form>
      </div>
    );
  }
}

Login = reduxForm({
  form: 'Login',
  enableReinitialize: true,
})(Login);



const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = {
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

