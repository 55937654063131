import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';
import {toastr} from 'react-redux-toastr'
import history from '../../lib/history';
import { I18n } from 'react-redux-i18n';

export function getProfile() {
  return async (dispatch) => {
    try {
      const result = await RestClient.get('profile', 'get');
      console.log("TCL: getPrifile -> result", result)
    
    } catch (error) {
      errorMessage(error)
    }
  }
}

export function resetPassword(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('profile', 'reset-password', body);
      toastr.success(I18n.t('titlePasswordNotification'), I18n.t('msgPasswordNotificationSaved'))
      return;
    } catch (error) {
      errorMessage(error)
    }
  }
}

export function uploadAvatar(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('profile', 'upload-avatar', body);
      console.log("TCL: getPrifile -> result", result)
      dispatch({type: 'SET_PROFILE', data: result.payload});
    } catch (error) {
      errorMessage(error)
    }
  }
}
