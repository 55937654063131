import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import FormConfirm from './components/FormConfirm';
import history from '../../../../lib/history';
import { setRoomInvite } from '../../actions';
import { connect } from 'react-redux'
import {toastr} from 'react-redux-toastr'


class Room extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: false, 
    }
    this.refModal = React.createRef();
  }

  goToQuezs = () => {
    const { room } = this.props;
    if(!room.is_done){
      return;
    }
    if(room.password){
      // this.setState({show_modal: true});
      if(this.refModal.current){
        this.refModal.current.show();
      }
    } else {
      history.push('/rooms/' + room.id)
    }
  }
  
  render() {
    const { room } = this.props;
    return (
      <div className="Room col-md-3 hvr-float-shadow">
        <FormConfirm room={room} ref={this.refModal} />
        <div className="item-container" style={{background: room.color}}>
          <div onClick={this.goToQuezs} className={classNames("item-content", {'cursor': room.is_done})}>
            <div className="logo">
              {!room.is_done ? <button onClick={() => {
                setRoomInvite(room.id);
                this.props.dispatch({type: 'UPDATE_ROOM', data: {...room, is_done: 1}});
                toastr.success(I18n.t('msgStudentTitleInvitedRoom'), I18n.t('msgStudentInvitedRoom'))
              }} className="btn btn-primary">{I18n.t('btnTitleConfirm')}</button> : null}
            </div>
            <h3>{room.title}</h3>
            
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  
})

export default connect(mapStateToProps)(Room)
