import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';
import history from '../../lib/history';
import {toastr} from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n';

export function getQuestions(quiz_id, student_answer_id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'questions', {quiz_id, student_answer_id})
      dispatch({type: 'GET_QUESTIONS', data: result.questions});
    } catch (error) {
      errorMessage(error)
    }
  }
}

export function getQuiz(quiz_id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'get', {quiz_id})
      if (!result.isValid) {
        history.push('/rooms/' + result.payload.room);
        toastr.error(I18n.t('titleErrorEndQuiz'), I18n.t('msgErrorQuizNotValid'))
      }
      dispatch({type: 'SET_QUESTIONS_QUIZ', data: result.payload});
      return {
        quiz: result.payload,
        is_start: result.is_start,
        is_limited: result.is_limited
      }
    } catch (error) {
      errorMessage(error)
    }
  }
}

export function setItem(data) {
  return  (dispatch) => {
    dispatch({type: 'SET_QUESTION_ITEM', data});
  }
}
export function clear() {
  return  (dispatch) => {
    dispatch({type: 'SET_QUESTION_CLEAR'});
  }
}

export function setAnswer(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'question-answer', body);
      dispatch({type: 'SET_QUESTION_ASWER', data: result.payload});
      toastr.success(I18n.t('msgStudentTitleAnswer'), I18n.t('msgStudentTitleAnswerSeved'))
      return null;
    } catch (error) {
      toastr.error(I18n.t('msgStudentTitleAnswerErro'), I18n.t('msgStudentTitleAnswerNotSeved'))
      errorMessage(error)
    }
  }
}


export function quizStart(quiz_id) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'start', {quiz_id})
      await dispatch({type: 'SET_QUESTION_STUDENT_ANSWER', data: result.payload, quiz: result.quiz});
      return result.payload;
    } catch (error) {
      history.push('/')
      errorMessage(error)
    }
  }
}

export function quizEnd(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('quiz', 'end', body);

      if (!result.isValid) {
        history.push('/rooms/' + result.quiz.room);
        toastr.error(I18n.t('titleErrorEndQuiz'), I18n.t('msgErrorQuizNotValid'))
        return;
      }

      if(!result.quiz.answers_after_done){
        history.push('/rooms/' + result.quiz.room)
        return;
      }

      const result_q = await RestClient.post('quiz', 'questions', body)
      dispatch({type: 'GET_QUESTIONS', data: result_q.questions}); 
      await dispatch({type: 'SET_QUESTION_STUDENT_ANSWER', data: result.payload});
      await dispatch({type: 'SET_QUESTION_ITEM', data: result_q.questions[0]});
      return null;
    } catch (error) {
      errorMessage(error)
    }
  }
}