import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.scss';
import { I18n } from 'react-redux-i18n';
import history from '../../../../lib/history';
import moment from 'moment';
import FieldInput from '../../../../components/Fields/FieldInput';

class StartPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      error_message: ''
    }
  }
  

  setConfirm = () => {
    const { quiz, setConfirm, is_limited } = this.props;

    if(quiz.password && quiz.password != this.state.password){
      this.setState({
        error_message: I18n.t('errorNotValidPasswordQuiz')
      })
      return;
    }

    setConfirm();
  }

  render() {
    const { quiz, setConfirm, is_limited } = this.props;
    console.log("TCL: StartPage -> render -> is_limited", is_limited)


    let valid_date = false;

    if(moment() >= moment(quiz.active_from * 1000) && moment() <= moment(quiz.active_to * 1000)){
      valid_date = true;
    }

    if(!valid_date){
      return (
        <div className="StartPage row">
          <h2 style={{textAlign: 'center', width: '100%'}}>{quiz.title}</h2>
          <div className="alert alert-danger error-message">{I18n.t('txtErrorMessageAvailableQuiz', {active_from: moment(quiz.active_from * 1000).format('LT DD/MM/YYYY'), active_to: moment(quiz.active_to * 1000).format('LT DD/MM/YYYY')})}</div>
          <div className="actions">
            <button type="button" className="btn btn-secondary" onClick={() => {history.push('/rooms/' + quiz.room)}}>{I18n.t('btnMsgBackQiuzs')}</button>
          </div>
        </div>
      );
    }


    return (
      <div className="StartPage row">
        <h2 style={{textAlign: 'center', width: '100%'}}>{quiz.title}</h2>
        {is_limited && <div className="alert alert-danger error-message">{I18n.t('msgErrorLimitAmswer')}</div>}
        {!is_limited && <div className="quiz-content col-md-12" dangerouslySetInnerHTML={{__html: quiz.description}} />}
        {quiz.password && <div className="form-password">
          <FieldInput
            meta={{
              error: this.state.error_message,
              touched: true
            }}
            label={I18n.t('labelPasswordQuiz')}
            type="password"
            input={{
              name: 'password',
              value: this.state.password,
              onChange: (e) => {
                this.setState({
                  password: e.target.value,
                  error_message: '',
                })
              }
            }}
          />
        </div>}
        <div className="actions">
          {<button type="button" className="btn btn-secondary" onClick={() => {history.push('/rooms/' + quiz.room)}}>{I18n.t('btnMsgBackQiuzs')}</button>}
          {!is_limited && <button type="button" className="btn btn-primary" onClick={this.setConfirm}>{I18n.t('btnMsgStartQiuz')}</button>}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  quiz: state.questions.quiz || {},
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPage)
