import React, { Component } from 'react';
import './style.scss';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import history from '../../../../lib/history';
import { connect } from 'react-redux'
import { quizStart } from '../../actions';
import {toastr} from 'react-redux-toastr'

class Quiz extends Component {
  goToQuiz = () => {
    const {quiz} = this.props;
    if(moment() >= moment(quiz.active_from*1000) && moment() <= moment(quiz.active_to*1000) ){
      history.push('/quiz/' + quiz.id)
    } else {
      toastr.error(I18n.t('titleErrorDateQuiz'), I18n.t('msgErrorQuizNotValidDate'))
    }
  }

  render() {
    const {quiz} = this.props;
    return (
      <div className="Quiz col-md-12" style={{background: quiz.color}}>
        <div className="header">
          <div className="title">
            <div className="name">{quiz.title}</div>
            <div className="info">{quiz.time_limit ? `${quiz.time_limit}min / ` : null }{quiz.count_questions} {I18n.t('questions')}</div>
          </div>
          <div className="description">
            <div>{I18n.t('txtAvailableQuiz', {active_from: moment(quiz.active_from * 1000).format('LT DD/MM/YYYY'), active_to: moment(quiz.active_to * 1000).format('LT DD/MM/YYYY')})}</div>
            
            {quiz.is_start && <div className="alert alert-danger">{I18n.t('inProgressQuiz')}</div>}
          </div>
        </div>
        <div className="actions">
            <i onClick={() => {
              history.push('/quiz-statistic/' + quiz.id)
            }} className="fas fa-signal"></i>
            <i onClick={this.goToQuiz} className="fas fa-play"></i>
          </div>
      </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = {
  quizStart
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz)

