import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import { i18nReducer } from 'react-redux-i18n';
import auth from './auth';
import rooms from '../container/Rooms/reducer'
import quizs from '../container/Quizs/reducer'
import questions from '../container/Questions/reducer'

export default combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  auth,
  i18n: i18nReducer,
  rooms,
  quizs,
  questions,
});
