import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';

export function getRooms() {
  return async (dispatch) => {
    try {
      const result = await RestClient.get('rooms', 'get')
      dispatch({type: 'GET_ROOMS', data: result.payload});
    } catch (error) {
      errorMessage(error)
    }
  }
}


export async function setRoomInvite(room_id) {
  try {
    await RestClient.post('room', 'set-room-invite', {room_id})
  } catch (error) {
    errorMessage(error)
  }
}