import React, { Component } from 'react';
import './style.scss';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators'
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import FieldInput from '../../components/Fields/FieldInput';
import history from '../../lib/history';
import { forgot } from './actions';


class Forgot extends Component {

  submit = async (data) =>{
    try {
      await this.props.forgot(data);
    } catch (error) {

    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="Forgot">
        <form className="form-login" onSubmit={handleSubmit(this.submit)}>
          <h1>{I18n.t('stPageForgotTitle')}</h1>
          <p>{I18n.t('stPageForgotDescription')}</p>
          <Field 
            name='email'
            label={I18n.t('stPageLoginTitleFieldEmail')}
            component={FieldInput}
            validate={[required()]}
          />
          <button type="submit" className="btn btn-primary">{I18n.t('stPageForgotTitleBtn')}</button>
          <button type="button" onClick={() => {history.push('/login')}} className="btn btn-light">{I18n.t('stPageForgotTitleBtnCancel')}</button>
        </form>
      </div>
    );
  }
}

Forgot = reduxForm({
  form: 'forgot',
  enableReinitialize: true,
})(Forgot);



const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = {
  forgot
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)

