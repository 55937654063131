import React, { Component } from 'react';
import { getQuizStatistic } from './actions';
import './style.scss';
import StaticItem from './components/StaticItem';
import history from '../../lib/history';
import { I18n } from 'react-redux-i18n';
import Spiner from '../../components/Spiner';


class QuizStatistic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      student_answers: [],
      quiz: {},
      questions_length: 0,
      loaded: false,
    }
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    
    const data = await getQuizStatistic(id);
    console.log("TCL: QuizStatistic -> componentDidMount -> data", data)
    this.setState({
      quiz: data.quiz,
      student_answers: data.payload,
      questions_length: data.questions_length,
      loaded: true
    })
  }
  
  render() {
    const { quiz, student_answers, questions_length, loaded } = this.state;

    if(!loaded){
      return <Spiner />
    }

    return (
      <div className="QuizStatistic row"> 
        <h2>{quiz.title}</h2> 
        <div className="statistic-items">
          {student_answers.map((item, index) => <StaticItem questions_length={questions_length} key={'item-' + item.id} item={item} index={index} />)}
        </div>
        <button type="button" className="btn btn-primary" onClick={() => {history.push('/rooms/' + quiz.room)}}>{I18n.t('btnMsgBackQiuzs')}</button>
      </div>
    );
  }
}

export default QuizStatistic;
