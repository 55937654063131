import React, { Component } from 'react';
import Step from './components/Step';
import { connect } from 'react-redux'
import { getQuestions, quizStart, getQuiz, clear } from './actions';
import StartPage from './components/StartPage';
import Spiner from '../../components/Spiner';

class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirm: false,
      loaded: false,
      is_limited: false,
    }
  }


  setConfirm = async () => {

    this.setState({confirm: true, loaded: false})

    const {id} = this.props.match.params;
    let student_answer = this.props.student_answer ? {...this.props.student_answer} : null;
    if(!student_answer || !student_answer.id){
      student_answer = await this.props.quizStart(id);
    }
    await this.props.getQuestions(id, student_answer.id);
    this.setState({confirm: true, loaded: true})
  }

  async componentDidMount(){
    const {id} = this.props.match.params;
    let data = await this.props.getQuiz(id);

    if(data.is_start){
      await this.setConfirm()
    }
    this.setState({
      loaded: true,
      is_limited: data.is_limited,
    })
  }

  componentWillUnmount(){
    this.props.clear();
  }

  render() {
    const { confirm, loaded, is_limited } = this.state;

    if(!loaded){
      return <Spiner />
    }
    return (
      <React.Fragment>
        {!confirm && <StartPage is_limited={is_limited} setConfirm={this.setConfirm} />}
        {confirm && <Step />}
      </React.Fragment>
      
    );
  }
}




const mapStateToProps = (state, ownProps) => ({
  student_answer: state.questions.student_answer,
})

const mapDispatchToProps = {
  getQuestions,
  quizStart,
  getQuiz,
  clear,
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions)

