import React, { Component } from 'react';
import { getQuizs } from './actions';
import { connect } from 'react-redux'
import './style.scss';
import Quiz from './components/Quiz';
import Spiner from '../../components/Spiner';
import { I18n } from 'react-redux-i18n';


class Quizs extends Component {
  state = {loaded: false}
  async componentDidMount(){
    const {id} = this.props.match.params;
    if(id){
      await this.props.getQuizs(id);
      this.setState({loaded: true})
    }
  }

  render() {
    const {quizs} = this.props;
    const { loaded } = this.state;

    if(!loaded){
      return <Spiner />
    }



    return (
      <div className="Quizs row">
        {quizs.length ? quizs.map(item => <Quiz quiz={item} key={'quiz-'+item.id} />) : <div className="msg-info">{I18n.t('msgNotFoundQiuzs')}</div>}
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  quizs: state.quizs.list,
})

const mapDispatchToProps = {
  getQuizs,
}

export default connect(mapStateToProps, mapDispatchToProps)(Quizs)
