const defaultState = {
  is_loaded: false,
  list: []
}

const rooms = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_ROOMS':
      return {
        ...state,
        list: action.data,
        is_loaded: true,
      };
    case 'UPDATE_ROOM':
      return {
        ...state,
        list: state.list.map(item => {
          if(action.data.id == item.id){
            return {...item, ...action.data}
          } 
          return {...item};
        })
      }  
    default:
      return state
  }
}

export default rooms;
