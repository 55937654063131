import RestClient from '../../config/rest-client';
import errorMessage from '../../lib/errorMessage';
import history from '../../lib/history';
import {toastr} from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n';

export function forgot(body) {
  return async (dispatch) => {
    try {
      const result = await RestClient.post('auth', 'forgot', body);
      toastr.success(I18n.t('msgForgotTitle'), I18n.t('msgForgotTextSendEmail'))
      history.push('/')
      return true;
    } catch (error) {
      errorMessage(error)
    }
  }
}

