import React, { Component } from 'react';
import './Spiner.scss';

class Spiner extends Component {
  render() {
    return (
      <div className="Spiner-page">
        <div className="loader">Loading...</div>
      </div>
    );
  }
}

export default Spiner;
