import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, confirmation } from 'redux-form-validators'
import './style.scss';
import FieldInput from '../../components/Fields/FieldInput';
import { confirm } from './actions';
import { I18n } from 'react-redux-i18n';

class Confirm extends Component {

  submit = async (data) =>{
    const {token} = this.props.match.params;
    try {
      await confirm({token, password: data.password})
      this.props.history.push('/login');
    } catch (error) {

    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="Confirm">
        <form className="form-confirm" onSubmit={handleSubmit(this.submit)}>
          <h1>{I18n.t('stPageConfirmTitle')}</h1>
          <p>{I18n.t('stPageConfirmDescription')}:</p>
          <Field 
            name='password'
            label={I18n.t('stPageConfirmFieldPassword')}
            component={FieldInput}
            validate={[required()]}
            type="password"
          />
          <Field 
            name='password_confirmation'
            label={I18n.t('stPageConfirmFieldPasswordConfirmation')}
            component={FieldInput}
            type="password"
            validate={[required(), confirmation({ field: 'password', fieldLabel: 'Password' })]}
          />
          <button type="submit" className="btn btn-primary">{I18n.t('stPageConfirmBtn')}</button>
        </form>
      </div>
    );
  }
}

Confirm = reduxForm({
  form: 'confirm',
  enableReinitialize: true,
})(Confirm);

export default Confirm;
