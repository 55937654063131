import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import history from '../../../../../../lib/history';
import FieldInput from '../../../../../../components/Fields/FieldInput';

class FormConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      value: '',
      error: ''
    }
  }

  toggle = () => {
    this.setState({show: false});
  }

  show = () => {
    this.setState({show: true});
  }
  
  submit = () => {
    const {value} =this.state;
    const {room} =this.props;
    if(!value) {
      this.setState({
        error: I18n.t('is re')
      });
      return;
    }

    if(value == room.password ){
      history.push('/rooms/' + room.id)
    } else {
      this.setState({
        error: I18n.t('invalid password')
      })
    }
  }

  render() {
    const {className} = this.props;
    const {show, error, value} =this.state;
    return (
      <Modal isOpen={show} toggle={this.toggle} className={className}>
        <ModalHeader toggle={this.toggle}>{I18n.t('modalTitleRoomPassword')}</ModalHeader>
        <ModalBody>
          <FieldInput meta={{error, touched: true}} input={{
            name: 'password',
            value,
            onChange: (e) => {
              this.setState({
                value: e.target.value,
                error: '',
              })
            }
          }} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.submit}>{I18n.t('btnModalOk')}</Button>{' '}
          <Button color="secondary" onClick={this.toggle}>{I18n.t('btnModalCancel')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}


export default FormConfirm;
