import React, { Component } from 'react';
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import './style.scss';
import { setItem } from '../../../../actions';
import classNames from 'classnames';

class QuestionsItems extends Component {
  render() {
    const {questions, active_item, student_answer} = this.props;
    const is_end_quiz = student_answer && student_answer.date_end > 0;

    return (
      <div className="QuestionsItems col-md-3">
        <div className="items">
          {questions.map((item, index) => (
            <div 
              key={'question-' + index}
              onClick={() => {
                this.props.setItem(item);
              }}
              className={
                classNames("item", {
                  active: active_item.id == item.id,
                  'answer-true': is_end_quiz && item.student_answer && item.correct_answer == item.student_answer.answer,
                  'answer-false': is_end_quiz && item.student_answer && item.correct_answer != item.student_answer.answer,
                })
              }
            >
              <i className="far fa-arrow-alt-circle-right carret"></i>
              <div>{I18n.t('Question')} {index + 1}</div>
              {item.is_answer && <i className="fas fa-check"></i>}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  questions: state.questions.list,
  student_answer: state.questions.student_answer,
  active_item: state.questions.active_item || {},
})

const mapDispatchToProps = {
  setItem
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsItems)
