import React, { Component } from 'react';
import QuestionsItems from './components/QuestionsItems';
import './style.scss';
import QuestionForm from './components/QuestionForm';

class Step extends Component {
  render() {
    return (
      <div className="Step row">
        <QuestionsItems />
        <QuestionForm />
      </div>
    );
  }
}

export default Step;
