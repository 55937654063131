import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux'
import { me, logout } from './actions';
import Layout from './container/Layout';
import LayoutBlank from './container/LayoutBlank';
import Spiner from './container/Spiner';

class DefaultRouter extends Component {

  async componentDidMount(){
    const is_confirm = window.location.pathname.indexOf('confirm-student') > -1;
    if(is_confirm){
      await this.props.logout(true);
    }
    await this.props.me();
  }

  render() {
    const { is_loaded, is_logined } = this.props;
    return (
      <Switch>
        <Route path="/" component={is_loaded ? (is_logined ? Layout : LayoutBlank) : Spiner} />
      </Switch>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_loaded: state.auth.is_loaded,
  is_logined: state.auth.is_logined,
})

const mapDispatchToProps = {
  me,
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter)

