import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import './style.scss';

class Statisctic extends Component {
  render() {
    const { quiz, questions, student_answer } = this.props;

    if(student_answer.date_end == 0){
      return null;
    }

    const procent = 100 / questions.length * student_answer.true_count_answer;

    return (
      <div className="Statisctic"> {I18n.t('txtYourScore')}: {procent.toFixed(0)}% ({student_answer.true_count_answer} {I18n.t('txtCorrectAnswer')}, {student_answer.count_answer - student_answer.true_count_answer} {I18n.t('txtIncorrectAnswer')}) </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  quiz: state.questions.quiz || {},
  questions: state.questions.list || {},
  student_answer: state.questions.student_answer || {},
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Statisctic)
