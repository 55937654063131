import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Field, reduxForm } from 'redux-form';
import { required, confirmation } from 'redux-form-validators'
import { connect } from 'react-redux'
import FieldInput from '../../../../components/Fields/FieldInput';
import { resetPassword } from '../../actions';

class ResetModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    };
  }

  submit = (data) => {
    this.props.resetPassword(data);
    this.setState({show: false});
  }
  
  render() {
    const { handleSubmit } = this.props;
    const {show} = this.state;
    return (
      <React.Fragment>
        <button className="btn btn-warning" onClick={() => {this.setState({show: true})}}>
          {I18n.t('btnResetPassword')}
        </button>
        <Modal isOpen={show} toggle={() => {this.setState({show: false})}}>
          <ModalHeader toggle={() => {this.setState({show: false})}}>{I18n.t('titleModalReset')}</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(this.submit)}>
              <Field 
                name='password'
                label={I18n.t('stModalResetTitleFieldPassword')}
                component={FieldInput}
                validate={[required()]}
                type="password"
              />
              <Field 
                name='password_confirm'
                label={I18n.t('stModalResetTitleFieldPasswordConfirm')}
                component={FieldInput}
                validate={[required(), confirmation({ field: 'password', fieldLabel: 'Password'  })]}
                type="password"
              />
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button type="submit" color="primary">{I18n.t('btnModalResetSave')}</Button>{' '}
                <Button type="button" color="secondary" onClick={() => {this.setState({show: false})}}>{I18n.t('btnModalResetCancel')}</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}


ResetModal = reduxForm({
  form: 'reset_password',
  enableReinitialize: true,
})(ResetModal);



const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = {
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetModal)

