import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './style.scss';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux'
import { logout } from '../../../actions';
import { I18n } from 'react-redux-i18n';
import history from '../../../lib/history';
import config from '../../../config';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    };
  }
  
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    const {isOpen} = this.state;
    const {is_logined, profile} = this.props;

    let avatar = '';

    if(profile.avatar != 'images/avatar/profile.png'){
      avatar = config.apiUrl + '/' +  profile.avatar;
    }

    return (
      <div className="Header">
        <div className="container">
        <Navbar light expand="md">
          <Link className="logo" to="/"><img src="/images/logo.png" alt="" /></Link>
          {is_logined && <NavbarToggler onClick={this.toggle} />}
          {is_logined && <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="mr-auto">
              <li className="nav-item menu-item">
                <Link className="nav-link" to="/">{I18n.t('navStDashboard')}</Link>
              </li>
              {/*<NavItem>
                <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
              </NavItem> */}
            </Nav>
            <Nav navbar>
              {/* <NavItem>
                <NavLink href="/components/">Components</NavLink>
              </NavItem> */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="profile-menu">
                  {I18n.t('msgHellowCode')} {profile.firstname} <div className="icon">
                    {avatar ? <div style={{backgroundImage: `url(${avatar})`}} className="avatar" /> : <i className="far fa-user"></i>}
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  
                  {/* <DropdownItem divider /> */}
                  <DropdownItem onClick={() => {
                    history.push('/profile')
                  }}>
                    {I18n.t('Profile')}
                  </DropdownItem>
                  <DropdownItem onClick={() => {
                    this.props.logout();
                  }}>
                    {I18n.t('Logout')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>}
        </Navbar>
        </div>
      </div>
      
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  is_logined: state.auth.is_logined,
  profile: state.auth.profile,
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

