import React, { Component } from 'react';
import Header from '../common/Header';
import { Switch, Route } from 'react-router';
import Rooms from '../Rooms';
import Quizs from '../Quizs';
import Questions from '../Questions';
import QuizStatistic from '../QuizStatistic';
import Profile from '../Profile';


class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="container">
          <Switch>
            <Route path="/profile" component={Profile} />
            <Route path="/rooms/:id" component={Quizs} />
            <Route path="/quiz/:id" component={Questions} />
            <Route path="/quiz-statistic/:id" component={QuizStatistic} />
            <Route path="/" component={Rooms} />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;
