const defaultState = {
  is_loaded: false,
  is_logined: false,
  profile: {}
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.data,
        is_loaded: true,
        is_logined: true,
      };
    case 'IS_LOADED':
      return {
        ...state,
        is_loaded: action.data,
      };
    case 'LOGOUT':
      return {
        is_loaded: true,
        is_logined: false,
        profile: {}
      };
    case 'IS_LOGINED':
      return {
        ...state,
        is_logined: action.data,
      };
    default:
      return state
  }
}

export default auth;
