import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.scss';
import { I18n } from 'react-redux-i18n';
import { findIndex } from 'lodash'
import { setItem, setAnswer, quizEnd, getQuestions } from '../../../../actions';
import { withRouter } from "react-router";
import classNames from 'classnames';
import history from '../../../../../../lib/history';
import Timer from '../../../Timer'
import Statisctic from '../../../Statistic';

class QuestionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.item.student_answer && props.item.student_answer.answer || '',
    }
  }

  onChange = (e) => {
    const { student_answer } = this.props;
    if(student_answer.date_end > 0){
      return;
    }
    this.setState({value: e.target.value}, () => {
      this.answer();
    });

  }

  next = () => {
    const { setItem, item, list} = this.props;
    const index = findIndex(list, ['id', item.id]);

    if(index < list.length-1){
      this.setState({value: ''});
      setItem(list[index+1]);
    }

  }

  previon = () => {
    const { setItem, item, list} = this.props;
    const index = findIndex(list, ['id', item.id]);

    if(index > 0){
      this.setState({value: ''});
      setItem(list[index-1])
    }
  }

  answer = async () => {
    if(!this.state.value){
      return;
    }
    const { item, student_answer } = this.props;

    if(student_answer.date_end > 0){
      return;
    }

    const { id } = this.props.match.params;
    await this.props.setAnswer({
      question_id: item.id, 
      quiz_id: id,
      student_answer_id: student_answer.id,
      answer: this.state.value,
      answer_order: Object.keys(item.answers).join(','),
      student_question_answer_id: item.student_answer ? item.student_answer.id : null
    });
    this.props.getQuestions(id, student_answer.id)
    // this.next();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.item.student_answer){
      this.setState({value: nextProps.item.student_answer.answer})
    } else {
      this.setState({value: ''})
    }
  }

  stopQuiz = () => {
    const { item, student_answer } = this.props;

    this.props.quizEnd({
      student_answer_id: student_answer.id,
      quiz_id: student_answer.quiz_id
    })
  }

  isAnswerTrue = (key) => {
    const { item, student_answer, quiz } = this.props;
    const is_end_quiz = student_answer && student_answer.date_end > 0;
    const { value } = this.state;
    // is_end_quiz && item.student_answer && quiz.answers_after_done_type && key == item.correct_answer
    if(is_end_quiz && item.student_answer && key == item.correct_answer && (quiz.answers_after_done_type || value == key)){
      return true;
    }
    return false;
  }
  

  isAnswerFalse = (key) => {
   
    const { item, student_answer, quiz } = this.props;
    
    const { value } = this.state;
    
    const is_end_quiz = student_answer && student_answer.date_end > 0;
    if(is_end_quiz && item.student_answer){
      console.log("isAnswerFalse -> item", item)
      console.log('++++++++++++++++++++')
      console.log("isAnswerFalse -> value", value);
      console.log("isAnswerFalse -> key", key);
      console.log("isAnswerFalse -> is_end_quiz", is_end_quiz);
      console.log("isAnswerFalse -> item.correct_answer", item.correct_answer);
      console.log('++++++++++++++++++++')
    }
    
    if(is_end_quiz && item.student_answer && key != item.correct_answer && value == key){
      return true;
    }
    return false; 
  }

  render() {
    const { item, list, student_answer, quiz } = this.props;
    if(!item || !item.id) {
      return null;
    }

    const is_end_quiz = student_answer && student_answer.date_end > 0;

    const { value } = this.state;

    const all_answer = !list.some(item => {
      if(item.is_answer){
        return false
      }
      return true;
    });


    let answers = {};

    if(item.student_answer && item.student_answer.answer_order){
      
      item.student_answer.answer_order.split(',').forEach(key => {
        answers[key] = item.answers[key];
      });
    } else {
      answers = item.answers;
    }

    const arrayChar = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let index = findIndex(list, ['id', item.id]);

    const description = item.description || I18n.t('msgExplanationIsNotProvided');

    return (
      <div className="QuestionForm col-md-9">
        <Timer />
        <Statisctic />
        <div className="question-content" dangerouslySetInnerHTML={{__html: item.question}}></div>
        <div className="question-answer">
          {answers ? Object.keys(answers).map((key, index) => (
            <div 
              key={'answer-' + item.id + '-' + index} 
              className={classNames("custom-control custom-radio question-answer-item", {
                'answer-true': this.isAnswerTrue(key), // TODO: && quiz.answers_after_done_type
                'answer-false': this.isAnswerFalse(key),
              })}
            >
              <input type="radio" id={'answer-' + item.id + '-' + index} name="answer" value={key} onChange={this.onChange} checked={value == key} className="custom-control-input"/>
              <label className="custom-control-label" htmlFor={'answer-' + item.id + '-' + index}><div className="char">{arrayChar[index]}</div>{item.answers[key]}</label>
            </div>
          )) : null}
        </div>
        {is_end_quiz && <div className="question-content" style={{marginTop: '20px'}} dangerouslySetInnerHTML={{__html: description}}></div>}
        <div className="actions">
          <button style={{visibility: index > 0 ? 'visible' : 'hidden'}} type="button" className="btn btn-primary" onClick={this.previon}>{I18n.t('btnMsgPrevionsQuestion')}</button>
          <div>{index + 1}/{list.length}</div>
          <button style={{visibility: index == list.length -1 ? 'hidden' : 'visible'}} type="button" className="btn btn-primary"  onClick={this.next}>{I18n.t('btnMsgNextQuestion')}</button>
        </div>
        <div className="action-submit">
          {student_answer.date_end == 0 
            ? 
              <button type="button" className={classNames("btn", {'btn-success': all_answer, 'btn-danger': !all_answer})} onClick={this.stopQuiz}>{all_answer ? I18n.t('btnMsgSubmitQuestion') : I18n.t('btnMsgStopQuiz')}</button>
            : 
              <button type="button" className="btn btn-primary" onClick={() => {history.push('/rooms/' + quiz.room)}}>{I18n.t('btnMsgBackToQuizzes')}</button>
          }
        </div> 
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  item: state.questions.active_item || {},
  student_answer: state.questions.student_answer || {},
  list: state.questions.list || {},
  quiz: state.questions.quiz || {},
})

const mapDispatchToProps = {
  setItem,
  setAnswer,
  quizEnd,
  getQuestions,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionForm))
