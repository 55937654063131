import React, { Component } from 'react';
import Header from '../common/Header';
import { Switch, Route } from 'react-router';
import Confirm from '../Confirm';
import Login from '../Login';
import Forgot from '../Forgot';

class LayoutBlank extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="container">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/confirm-student/:token" component={Confirm} />
            <Route path="/" component={Login} />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default LayoutBlank;
