import RestClient from './config/rest-client';
import { loadTranslations, setLocale } from 'react-redux-i18n';

export function me() {
  return async (dispatch) => {
    try {
      let translates = await RestClient.get('auth', 'translates');
      dispatch(loadTranslations(translates.data));
      dispatch(setLocale('en'));
      let result = await RestClient.get('auth', 'me');
      if(result.errcode == 200){
        dispatch({type: 'SET_PROFILE', data: result.user});
      } else {
        dispatch({type: 'IS_LOADED', data: true});
      }
    } catch (error) {
      dispatch({type: 'IS_LOADED', data: true});
    }
  }
}

export function logout(notReducer=false) {
  return async (dispatch) => {
    let result = await RestClient.post('auth', 'logout', {});
    if(!notReducer){
      dispatch({type: 'LOGOUT'});
    }
    RestClient.config({
      headers: {
        'Token': '',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    localStorage.removeItem('token');
  }
}