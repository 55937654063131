import React from 'react';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import store from './store';
import history from './lib/history';
import DefaultRouter from './DefaultRouter';
import ReduxToastr from 'react-redux-toastr'

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ReduxToastr/>
        <DefaultRouter />
      </Router>
    </Provider>
  );
}

export default App;
