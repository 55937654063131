import React, { Component } from 'react';
import './style.scss';

class Spiner extends Component {
  render() {
    return (
      <div className="Spiner">
        <img src="/images/logo-animated.gif" alt="" />
      </div>
    );
  }
}

export default Spiner;
