import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux'
import { quizEnd } from '../../actions';
import './style.scss';
import getTimeHHMMSS from '../../../../lib/getTimeHHMMSS';

class Timer extends Component {
  constructor(props) {
    super(props);

    const seconds = props.quiz.time_limit * 60 - (Math.round(new Date().getTime()/1000) - props.student_answer.date_start);

    this.state = {
      seconds: seconds > 0 ? seconds : 0,
      timer: null
    }
    this.timer = null;
    
  }

  componentDidMount(){
    if(this.props.quiz.time_limit > 0 && this.props.student_answer.date_end == 0){
      this.timer = setInterval(() => {
        const {seconds} = this.state;
        const {student_answer} = this.props;
        if(seconds <= 0){
          clearInterval(this.timer);
          if(student_answer.date_end == 0){
            this.props.quizEnd({
              student_answer_id: student_answer.id,
              quiz_id: student_answer.quiz_id
            })
          }
          return;
        }
        this.setState({seconds: seconds - 1})
      }, 1000);
    }
  }

  _getTimes = () => {
    const minutes = Math.floor(this.state.seconds / 60);
    const seconds = this.state.seconds - minutes * 60;
    return {
      minutes_10: minutes >= 10 ? Math.floor(minutes / 10) : 0,
      minutes: minutes >= 10 ? minutes - Math.floor(minutes / 10) * 10 : minutes,
      seconds_10: seconds >= 10 ? Math.floor(seconds / 10) : 0,
      seconds: seconds >= 10 ? seconds - Math.floor(seconds / 10) * 10 : seconds,
    };
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  
  render() {
    const {quiz, student_answer} = this.props;
    
    if(!quiz.time_limit || student_answer.date_end > 0){
      return null;
    }
    const timers = getTimeHHMMSS(this.state.seconds);

    return (
      <div className="Timer"> 
        <div className="wrap-clock">
          <div className="title">{I18n.t('titleTimer')}:</div> <div className="clock">{timers}</div></div>
        </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  quiz: state.questions.quiz || {},
  student_answer: state.questions.student_answer || {},
})

const mapDispatchToProps = {
  quizEnd
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)
